import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, Button, Modal, Box, TextField } from '@mui/material';
import API_BASE_URL from '../apiConfig';

const Home = ({ token }) => {
  const [domains, setDomains] = useState([]);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [telegramUsername, setTelegramUsername] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [checkMessage, setCheckMessage] = useState('');

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/user_domains`, {
          headers: { Authorization: token }
        });
        setDomains(response.data.data);
      } catch (error) {
        setError('Failed to fetch domains');
      }
    };

    fetchDomains();
  }, [token]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleTelegramUpdate = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/api/update_telegram`, 
        { telegram: telegramUsername },
        {
          headers: { Authorization: token }
        }
      );
      if (response.data.changes > 0) {
        setSuccessMessage('Telegram username updated successfully. Please run /reg in the Telegram bot.');
        handleClose();
      }
    } catch (error) {
      setError('Failed to update Telegram username');
    }
  };

  const handleCheckDomains = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/trigger_background_task`, {}, {
        headers: { Authorization: token }
      });
      setCheckMessage(response.data.message);
    } catch (error) {
      setError('Failed to trigger domain check');
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Domain List
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {successMessage && <Typography color="primary">{successMessage}</Typography>}
      {checkMessage && <Typography color="primary">{checkMessage}</Typography>}
      <Button variant="contained" color="primary" onClick={handleOpen} style={{ marginRight: '10px' }}>
        Add Telegram Username
      </Button>
      <Button variant="contained" color="secondary" onClick={handleCheckDomains}>
        Check Domains
      </Button>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Domain Name</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date Added</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {domains.map((domain) => (
              <TableRow key={domain.id}>
                <TableCell>{domain.domain_name}</TableCell>
                <TableCell>{domain.project_name || 'No Project'}</TableCell>
                <TableCell>{domain.status}</TableCell>
                <TableCell>{new Date(domain.created_at).toLocaleDateString()}</TableCell>
                <TableCell>{domain.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Modal open={open} onClose={handleClose}>
        <Box 
          sx={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
            width: 400, 
            bgcolor: 'background.paper', 
            border: '2px solid #000', 
            boxShadow: 24, 
            p: 4 
          }}
        >
          <Typography variant="h6" component="h2">
            Add Telegram Username
          </Typography>
          <TextField
            label="Telegram Username"
            fullWidth
            value={telegramUsername}
            onChange={(e) => setTelegramUsername(e.target.value)}
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleTelegramUpdate}>
            Submit
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default Home;
