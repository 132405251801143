import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper,
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  TextField, Box, IconButton
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import API_BASE_URL from '../apiConfig';

const Servers = ({ token }) => {
  const [servers, setServers] = useState([]);
  const [newServerName, setNewServerName] = useState('');
  const [newServerIP, setNewServerIP] = useState('');
  const [newServerUser, setNewServerUser] = useState('');
  const [newServerPassword, setNewServerPassword] = useState('');
  const [error, setError] = useState('');
  const [openAddDialog, setOpenAddDialog] = useState(false);

  useEffect(() => {
    fetchServers();
  }, [token]);

  const fetchServers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/servers`, {
        headers: { Authorization: token }
      });
      setServers(response.data.data);
    } catch (error) {
      setError('Failed to fetch servers');
    }
  };

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddServer = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/servers`, {
        name: newServerName,
        ssh_host: newServerIP,
        ssh_user: newServerUser,
        ssh_password: newServerPassword
      }, {
        headers: { Authorization: token }
      });
      fetchServers();
      handleCloseAddDialog();
      setNewServerName('');
      setNewServerIP('');
      setNewServerUser('');
      setNewServerPassword('');
    } catch (error) {
      setError('Failed to add server');
    }
  };

  const handleDeleteServer = async (serverId) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/servers/${serverId}`, {
        headers: { Authorization: token }
      });
      fetchServers();
    } catch (error) {
      setError('Failed to delete server');
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Servers
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <Box display="flex" justifyContent="flex-end" marginBottom={2}>
        <Button variant="contained" color="primary" onClick={handleOpenAddDialog}>
          Add Server
        </Button>
      </Box>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>IP Address</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Password</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {servers.map((server) => (
              <TableRow key={server.id}>
                <TableCell>{server.name}</TableCell>
                <TableCell>{server.ssh_host}</TableCell>
                <TableCell>{server.ssh_user}</TableCell>
                <TableCell>{server.ssh_password}</TableCell>
                <TableCell>{new Date(server.created_at).toLocaleDateString()}</TableCell>
                <TableCell>
                  <IconButton color="secondary" onClick={() => handleDeleteServer(server.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>Add Server</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the details of the new server.
          </DialogContentText>
          <TextField
            label="Server Name"
            value={newServerName}
            onChange={(e) => setNewServerName(e.target.value)}
            margin="normal"
            fullWidth
          />
          <TextField
            label="IP Address"
            value={newServerIP}
            onChange={(e) => setNewServerIP(e.target.value)}
            margin="normal"
            fullWidth
          />
          <TextField
            label="User"
            value={newServerUser}
            onChange={(e) => setNewServerUser(e.target.value)}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Password"
            value={newServerPassword}
            onChange={(e) => setNewServerPassword(e.target.value)}
            margin="normal"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddServer} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Servers;
