import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Button, Table, TableBody, TableCell, TableHead, TableRow, Snackbar, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import API_BASE_URL from '../apiConfig';

const DomainTransferHistoryDialog = ({ open, onClose, token, domainId, onDomainDeleted }) => {
  const [transferHistory, setTransferHistory] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedDomainType, setSelectedDomainType] = useState(null);
  const [newMainDomainId, setNewMainDomainId] = useState('');
  const [domains, setDomains] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const fetchTransferHistory = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/domain_transfer_history/${domainId}`, {
          headers: { Authorization: token }
        });
        setTransferHistory(response.data.data);
      } catch (error) {
        console.error('Failed to fetch domain transfer history', error);
      }
    };

    if (open) {
      fetchTransferHistory();
    }
  }, [token, domainId, open]);

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/domains`, {
          headers: { Authorization: token }
        });
        setDomains(response.data.data.filter(domain => domain.id !== domainId && domain.type === 'main'));
      } catch (error) {
        console.error('Failed to fetch domains', error);
      }
    };

    fetchDomains();
  }, [token, domainId]);

  const handleDelete = async () => {
    try {
      if (selectedDomainType === 'main') {
        await axios.post(`${API_BASE_URL}/api/update_redirects`, {
          oldMainDomainId: selectedDomain.main_domain_id,
          newMainDomainId,
        }, {
          headers: { Authorization: token }
        });
      } else {
        await axios.delete(`${API_BASE_URL}/api/domains/${selectedDomain.main_domain_id}`, {
          headers: { Authorization: token }
        });
      }
      setSnackbarOpen(true);
      setDeleteDialogOpen(false);
      setSelectedDomain(null);
      setSelectedDomainType(null);
      onDomainDeleted(); // Update the parent page
    } catch (error) {
      console.error('Failed to delete domain', error);
    }
  };

  const handleDeleteClick = async (record) => {
    setSelectedDomain(record);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/domains/${record.main_domain_id}`, {
        headers: { Authorization: token }
      });
      setSelectedDomainType(response.data.data.type);
      setDeleteDialogOpen(true);
    } catch (error) {
      console.error('Failed to fetch domain type', error);
    }
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setSelectedDomain(null);
    setSelectedDomainType(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Domain Transfer History</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Below is the history of transfers for the domain.
          </DialogContentText>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Start Domain</TableCell>
                <TableCell>End Domain</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transferHistory.map((record) => (
                <TableRow key={record.id}>
                  <TableCell>{record.id}</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>{record.main_domain_name}</TableCell>
                  <TableCell>{record.transfer_domain_name}</TableCell>
                  <TableCell>{record.action}</TableCell>
                  <TableCell>{new Date(record.created_at).toLocaleString()}</TableCell>
                  <TableCell>
                    <Button color="secondary" onClick={() => handleDeleteClick(record)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the domain <strong>{selectedDomain && selectedDomain.main_domain_name}</strong>?
          </DialogContentText>
          {selectedDomainType === 'main' && (
            <FormControl margin="normal" fullWidth>
              <InputLabel>New Main Domain</InputLabel>
              <Select
                value={newMainDomainId}
                onChange={(e) => setNewMainDomainId(e.target.value)}
              >
                {domains.map((domain) => (
                  <MenuItem key={domain.id} value={domain.id}>
                    {domain.domain_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message="Domain deleted successfully"
        autoHideDuration={6000}
      />
    </>
  );
};

export default DomainTransferHistoryDialog;
