import React from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { AppBar, Toolbar, Typography, Button, ButtonGroup, Box } from '@mui/material';

const Dashboard = ({ token, setToken }) => {
  const user = jwtDecode(token);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    setToken('');
    localStorage.removeItem('token');
    navigate('/login');
  };

  const isSelected = (path) => location.pathname === path;

  const buttonStyle = (path) => ({
    backgroundColor: isSelected(path) ? '#2F3235' : 'inherit',
    borderRadius: isSelected(path) ? '4px' : '0px',
    color: isSelected(path) ? '#fff' : 'inherit',
  });

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
          <ButtonGroup variant="text" color="inherit">
            <Button component={Link} to="/" sx={buttonStyle('/')}>Home</Button>
            <Button component={Link} to="/projects" sx={buttonStyle('/projects')}>Projects</Button>
            <Button component={Link} to="/dns" sx={buttonStyle('/dns')}>Change DNS</Button>
            <Button component={Link} to="/cloudflare" sx={buttonStyle('/cloudflare')}>Cloudflare Accounts</Button>
            <Button component={Link} to="/servers" sx={buttonStyle('/servers')}>Servers</Button>
            {user.role === 'admin' && (
              <Button component={Link} to="/admin" sx={buttonStyle('/admin')}>Create User</Button>
            )}
            {user.role === 'admin' && (
              <Button component={Link} to="/tables" sx={buttonStyle('/tables')}>Tables</Button>
            )}
          </ButtonGroup>
          <Button color="inherit" onClick={handleLogout}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Box p={2}>
        <Outlet />
      </Box>
    </div>
  );
};

export default Dashboard;
