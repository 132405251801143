import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Typography, Button, Box, FormControl, InputLabel, Select, MenuItem,
  CircularProgress, Snackbar, Alert
} from '@mui/material';
import API_BASE_URL from '../apiConfig';

const ChangeDNS = ({ token }) => {
  const [cloudflareAccounts, setCloudflareAccounts] = useState([]);
  const [servers, setServers] = useState([]);
  const [domains, setDomains] = useState([]);
  const [filteredDomains, setFilteredDomains] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedServer, setSelectedServer] = useState('');
  const [selectedDomain, setSelectedDomain] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    fetchCloudflareAccounts();
    fetchServers();
    fetchDomains();
  }, [token]);

  const fetchCloudflareAccounts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/cloudflare_accounts`, {
        headers: { Authorization: token }
      });
      setCloudflareAccounts(response.data.data);
    } catch (error) {
      setError('Failed to fetch Cloudflare accounts');
    }
  };

  const fetchServers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/servers`, {
        headers: { Authorization: token }
      });
      setServers(response.data.data);
    } catch (error) {
      setError('Failed to fetch servers');
    }
  };

  const fetchDomains = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/domains`, {
        headers: { Authorization: token }
      });
      setDomains(response.data.data);
    } catch (error) {
      setError('Failed to fetch domains');
    }
  };

  const handleChangeDNS = async () => {
    setLoading(true);
    setMessage('');
    setError('');
    try {
      const response = await axios.post(`${API_BASE_URL}/api/manage_dns`, {
        accountId: selectedAccount,
        domainName: selectedDomain,
        ipAddress: ipAddress
      }, {
        headers: { Authorization: token }
      });
      setLoading(false);
      setMessage(response.data.message);
      setOpenSnackbar(true);
    } catch (error) {
      setLoading(false);
      setError('Failed to change DNS');
      setOpenSnackbar(true);
    }
  };

  const handleServerChange = (e) => {
    const selectedServerId = e.target.value;
    const selectedServer = servers.find((server) => server.id === selectedServerId);
    setSelectedServer(selectedServerId);
    setIpAddress(selectedServer.ssh_host);

    // Фильтруем домены в соответствии с выбранным сервером
    const filtered = domains.filter((domain) => domain.server_id === selectedServerId);
    setFilteredDomains(filtered);
    setSelectedDomain(''); // Сбрасываем выбранный домен при смене сервера
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Change DNS Records
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {message && <Typography color="primary">{message}</Typography>}
      <Box display="flex" flexDirection="column" maxWidth="400px" margin="0 auto">
        <FormControl margin="normal" fullWidth>
          <InputLabel>Cloudflare Account</InputLabel>
          <Select
            value={selectedAccount}
            onChange={(e) => setSelectedAccount(e.target.value)}
          >
            {cloudflareAccounts.map((account) => (
              <MenuItem key={account.id} value={account.id}>
                {account.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel>Server</InputLabel>
          <Select
            value={selectedServer}
            onChange={handleServerChange}
          >
            {servers.map((server) => (
              <MenuItem key={server.id} value={server.id}>
                {server.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel>Domain</InputLabel>
          <Select
            value={selectedDomain}
            onChange={(e) => setSelectedDomain(e.target.value)}
            disabled={!selectedServer} // Отключаем выбор домена, если сервер не выбран
          >
            {filteredDomains.map((domain) => (
              <MenuItem key={domain.id} value={domain.domain_name}>
                {domain.domain_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleChangeDNS}
          margin="normal"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Change DNS'}
        </Button>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={error ? "error" : "success"}>
          {error || message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ChangeDNS;
